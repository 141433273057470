var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "IntersectionObserver",
    {
      attrs: {
        "observe-once": "",
        options: {
          threshold: [0.0],
          trackVisibility: true,
          delay: 100
        }
      },
      on: { intersect: _vm.onIntersect }
    },
    [
      _c("img", {
        class: _vm.classes,
        style: _vm.styles,
        attrs: { src: _vm.image, alt: "Image loaded asynchronously" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }