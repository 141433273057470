var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _vm.isHeaderSlotVisible
      ? _c(
          "div",
          { staticClass: "center-view-template__header" },
          [_vm._t("header")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "center-view-template__body" },
      [
        _vm._t("content", function() {
          return [
            _c("VerticalFixedScroll", [
              _c(
                "div",
                { staticClass: "container" },
                [_c("VerticalCenteredView", [_vm._t("centeredContent")], 2)],
                1
              )
            ])
          ]
        }),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }